import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledPagesPreview = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: ${Rem(300)};

  @media (${BP.ipad}) {
    height: ${Rem(560)};
  }
`;

export const StyledPagesPreviewPage = styled.div`
  position: relative;
  width: 80%;
  height: 100%;
  margin-left: ${Rem(20)};
  overflow: hidden;

  @media (${BP.ipad}) {
    margin-left: 0;
    width: calc(50% - ${Rem(10)});
    height: calc(50% - ${Rem(10)});
    font-size: ${Rem(25)};
  }

  &:first-child {
    @media (${BP.ipad}) {
      height: 100%;
      margin-right: ${Rem(10)};
    }
  }

  &:last-child {
    &:after {
      position: relative;
      content: '';
      display: block;
      width: ${Rem(20)};
      height: 100%;
      left: 100%;
      background-color: ${Colors.transparent};

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }
`;

export const StyledPagesPreviewPageTitle = styled.h2`
  font-weight: bold;
  font-size: ${Rem(20)};
  color: ${Colors.white};
  @media (${BP.ipad}) {
    font-size: ${Rem(26)};
  }
`;

export const StyledPagesPreviewPageSubtitle = styled.h3`
  font-size: ${Rem(16)};
  font-weight: 100;
  color: ${Colors.white};
  margin-top: 10px;
  @media (${BP.ipad}) {
    font-size: ${Rem(20)};
  }
`;

export const StyledPagesPreviewPageMedia = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
`;

export const StyledPagesPreviewPageCta = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;

  a {
    text-decoration: none;
  }
`;
